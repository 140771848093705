import React, { useEffect, useState } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import { Typography } from '@mui/material';
import {
  useNotificationsLazyQuery,
  Notification,
} from '../../shared/types/generated';
import { useAuth } from '../auth/auth-hook';
import moment from 'moment/moment';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';

export const Notifications: React.FC = () => {
  const { user } = useAuth();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 1,
  });
  const [count, setCount] = useState(0);

  const [callAction, { data, loading }] = useNotificationsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setCount(res.notificationList?.count || 0);
    },
  });
  useEffect(() => {
    if (user) {
      callAction({
        variables: {
          where: {
            user_id: user.id,
          },
          page: pagination.page - 1,
          pageSize: pagination.pageSize,
        },
      });
    }
  }, [user, callAction, pagination.page, pagination.pageSize]);

  const notifications = (data?.notificationList?.notifications ||
    []) as Notification[];
  const COLUMNS: TableColumnType<Notification>[] = [
    {
      columnName: 'Fecha',
      columnValue: (item: Notification) =>
        moment(parseInt(item.created_at as string)).format('DD-MM-YYYY HH:mm'),
    },
    {
      columnName: 'Mensaje',
      columnValue: (item: Notification) => item.message as string,
    },
  ];

  return (
    <CardSection
      title={
        <Typography
          fontFamily="AgencyBold"
          textTransform={'uppercase'}
          letterSpacing={0.5}
          variant="h6"
          fontWeight={800}
        >
          Notificaciones
        </Typography>
      }
      subheader={
        <Typography variant="subtitle1">
          Acá encontrarás información detallada del historial de notificaciones.
        </Typography>
      }
    >
      <TableDefault
        count={count}
        items={notifications}
        columns={COLUMNS}
        page={pagination.page}
        pageSize={pagination.pageSize}
        loading={loading}
        messageLoading={'Cargando notificaciones...'}
        showPagination
        handleChangePage={(page) => {
          setPagination({
            ...pagination,
            page: page + 1,
          });
        }}
      />
    </CardSection>
  );
};
