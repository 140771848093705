import React from 'react';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import { useGoogleLogin } from '@react-oauth/google';
import { openDialogAlert } from '../../alert/alert-actions';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import GoogleIcon from '@mui/icons-material/Google';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  SocialProviders,
  useConnectUserGoogleMutation,
} from '../../../shared/types/generated';
import { providerConnectStoreAction } from '../setting-actions';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    connect: {
      background: `${PRIMARY_COLOR}!important`,
      color: 'black!important',
      fontWeight: ' bold !important',
      width: '130px',
      height: '38px !important',
    },
    disconnect: {
      background: '#efefef!important',
      color: `${PRIMARY_COLOR}!important`,
      border: '1px solid #FA0000',
      width: '130px',
      height: '38px !important',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
    disable: {
      background: '#efefef!important',
      opacity: '65% !important',
    },
  }),
);

export const GoogleConnectButton: React.FC = () => {
  const classes = useStyles();
  const { user, refetch } = useAuth();
  const isConnected = user?.social_providers?.find(
    (socialProvider) => socialProvider?.provider === SocialProviders.Google,
  );

  const [callbackAction, { loading }] = useConnectUserGoogleMutation({
    onCompleted: () => {
      refetch();
      return openDialogAlert(
        'Se ha conectado tu cuenta de Google correctamente',
      );
    },
    onError: (error) => {
      return openDialogAlert(error.message);
    },
  });

  const handleDisconnect = (): void => {
    if (isConnected?.primary)
      return openDialogAlert('No se puede desconectar su cuenta principal');

    providerConnectStoreAction(true, SocialProviders.Google);
  };

  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      callbackAction({
        variables: {
          data: {
            token: access_token,
          },
        },
      });
    },

    onError: () => openDialogAlert('Tenemos problemas con google'),
  });

  return (
    <Grid container>
      <Grid item xs={6} sm={8} alignItems="center">
        <Grid container height={'100%'}>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <GoogleIcon />
            <Typography paddingLeft={2}>Google</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            paddingTop={1}
            display="flex"
            alignItems="center"
          >
            {isConnected ? (
              <Box display={'flex'} alignItems={'center'}>
                <CheckCircleOutlineIcon
                  fontSize="small"
                  style={{ color: 'green' }}
                />
                <Box style={{ color: 'green' }} className={classes.textSocial}>
                  Conectada
                </Box>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        sm={4}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ButtonDefault
          isLoading={loading}
          className={!isConnected ? classes.connect : classes.disconnect}
          variant="outlined"
          style={{ width: 160 }}
          onClick={() => (!isConnected ? googleLogin() : handleDisconnect())}
        >
          {!isConnected ? 'Conectar' : 'desconectar'}
        </ButtonDefault>
      </Grid>
    </Grid>
  );
};
