import React, { ChangeEvent, useEffect, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import { useAuth } from '../../auth/auth-hook';
import {
  SubscriptionBilling,
  useCreateSubscriptionBillingMutation,
  useUpdateSubscriptionBillingMutation,
} from '../../../shared/types/generated';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '6px!important',
  backgroundColor: `${PRIMARY_COLOR}!important`,
  border: 'none!important',
  color: 'black!important',
  fontWeight: 'bold !important',
  height: '38px !important',
  '&:disabled': {
    backgroundColor: '#FF9EA1!important',
  },
  width: 200,
});
export const SubscriptionBillingForm: React.FC = () => {
  const { user, refetch } = useAuth();

  const [subscriptionBilling, setSubscriptionBilling] = useState<
    SubscriptionBilling | undefined | null
  >(undefined);

  useEffect(() => {
    if (user && user.subscription_billing) {
      setSubscriptionBilling(user.subscription_billing);
    }
  }, [user, user?.subscription_billing]);

  const [callCreateSubscriptionBilling, { loading: createLoading }] =
    useCreateSubscriptionBillingMutation({
      onCompleted: () => {
        refetch();
      },
    });
  const [callUpdateSubscriptionBilling, { loading: updateLoading }] =
    useUpdateSubscriptionBillingMutation({
      onCompleted: () => {
        refetch();
      },
    });

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setSubscriptionBilling({
      ...subscriptionBilling,
      [event.target.name as keyof SubscriptionBilling]: event.target.value,
    });
  };

  const onHandleSubmit = (): void => {
    console.log({ subscriptionBilling });
    if (subscriptionBilling?.id) {
      // eslint-disable-next-line @typescript-eslint/naming-convention,no-unused-vars

      callUpdateSubscriptionBilling({
        variables: {
          data: {
            country: subscriptionBilling.country,
            address1: subscriptionBilling.address1,
            address: subscriptionBilling.address,
            city: subscriptionBilling.city,
            state: subscriptionBilling.state,
            postal_code: subscriptionBilling.postal_code,
            phone: subscriptionBilling.phone,
            phone1: subscriptionBilling.phone1,
            id: subscriptionBilling.id,
          },
        },
      });
    } else {
      callCreateSubscriptionBilling({
        variables: {
          data: {
            ...subscriptionBilling,
          },
        },
      });
    }
  };

  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'Calle y numero'}
          name="address"
          fullWidth
          value={subscriptionBilling?.address}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'Colonia'}
          name="address1"
          value={subscriptionBilling?.address1}
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'Ciudad'}
          name="city"
          value={subscriptionBilling?.city}
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'Estado'}
          name="state"
          value={subscriptionBilling?.state}
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'Pais'}
          name="country"
          value={subscriptionBilling?.country}
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'C.P'}
          value={subscriptionBilling?.postal_code}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          name="postal_code"
          fullWidth
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'Numero de Contacto'}
          name="phone"
          value={subscriptionBilling?.phone}
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          placeholder={'Numero de Contacto (opcional)'}
          name="phone1"
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          value={subscriptionBilling?.phone1}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <Box
          alignItems={'center'}
          justifyContent={'center'}
          display={'flex'}
          width={'full'}
        >
          <StyledButton
            isLoading={createLoading || updateLoading}
            onClick={() => onHandleSubmit()}
          >
            Guardar cambios
          </StyledButton>
        </Box>
      </Grid>
    </Grid>
  );
};
