import React from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import { Box, Grid, Typography } from '@mui/material';
import { useClaimCodeMutation } from '../../shared/types/generated';
import { useAuth } from '../auth/auth-hook';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { openDialogAlert } from '../alert/alert-actions';
import { PRIMARY_COLOR } from '../../shared/css/theme';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { styled } from '@mui/styles';

const StyledButton = styled(ButtonDefault)({
  borderRadius: '6px!important',
  backgroundColor: `${PRIMARY_COLOR}!important`,
  border: 'none!important',
  color: 'white!important',
  height: '38px !important',
  '&:disabled': {
    backgroundColor: '#FF9EA1!important',
  },
  width: 245,
});

export const Convoy: React.FC = () => {
  const { user, refetch } = useAuth();
  const [callConvoyCode, { loading }] = useClaimCodeMutation({
    onCompleted: () => refetch(),
  });
  const subscription = user?.subscriptions?.length
    ? user?.subscriptions[0]
    : undefined;

  return (
    <>
      <Box mb={2}>
        <CardSection
          title={
            <Typography variant="h6" fontWeight={800}>
              ¡Bienvenid@ a Convoy!
            </Typography>
          }
          subheader={
            <Typography variant="subtitle1">
              En Animal Político nos gusta contar lo que acontece en la
              actualidad y en esta ocasión, lo hacemos con pódcast pensados en
              los temas que más te interesan. Al elegir este beneficio, estamos
              listos para enviarte el código que canjearás para ser parte de
              Convoy.
            </Typography>
          }
        >
          <Grid container px={2} mb={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" mb={2}></Typography>
              <Typography variant="subtitle1">
                Las instrucciones son muy fáciles:
              </Typography>
              <Typography variant="subtitle1">1.- Abre el enlace</Typography>
              <Typography variant="subtitle1">
                2.- Selecciona la plataforma desde la que vas iniciar tu sesión
              </Typography>
              <Typography variant="subtitle1">3.- Ingresa tu código</Typography>
              <Typography variant="subtitle1" mb={2}>
                4.- ¡Disfruta de los contenidos de Convoy y los que la Familia
                Animal tiene para ti!
              </Typography>
              <Typography variant="subtitle1" mb={2}>
                Cualquier duda, escríbenos a{' '}
                <a
                  href="mailto:suscripciones@animalpolitico.com"
                  style={{ color: PRIMARY_COLOR, textDecoration: 'underline' }}
                >
                  suscripciones@animalpolitico.com
                </a>{' '}
                y te ayudamos con gusto. Y de parte de la Familia Animal,
                gracias por apoyar el periodismo libre en México
              </Typography>
            </Grid>
            <Grid item xs={12} mb={1}>
              {user?.convoy_code ? (
                <Box>
                  <Typography>
                    Código:
                    <CopyToClipboard
                      text={user?.convoy_code.code as string}
                      onCopy={() =>
                        openDialogAlert('Se ha copiado el código con éxito')
                      }
                    >
                      <Box
                        ml={1}
                        bgcolor="#C8C6C4"
                        component="span"
                        borderRadius={1}
                        py={0.2}
                        px={0.5}
                        fontWeight={700}
                        style={{ cursor: 'pointer' }}
                      >
                        {user?.convoy_code.code}
                      </Box>
                    </CopyToClipboard>
                  </Typography>
                </Box>
              ) : (
                <StyledButton
                  isLoading={loading}
                  onClick={() => {
                    if (!subscription)
                      openDialogAlert(
                        'Debes tener una suscripción  para poder obtener tu código Convoy',
                      );
                    else {
                      callConvoyCode();
                    }
                  }}
                >
                  Adquirir código convoy
                </StyledButton>
              )}
            </Grid>
            <Grid item xs={12}>
              Enlace:{' '}
              <a
                href="https://animalpolitico.convoynetwork.com"
                target="_blank"
                style={{ color: PRIMARY_COLOR, textDecoration: 'underline' }}
              >
                https://animalpolitico.convoynetwork.com
              </a>
            </Grid>
          </Grid>
        </CardSection>
      </Box>
    </>
  );
};
