export const BILLING_INFO = {
  address: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postal_code: '',
  phone: '',
  phone1: '',
};
