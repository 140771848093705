import React from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useCookies } from 'react-cookie';
import { Site, useGoogleSignupMutation } from '../../../shared/types/generated';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constans';
import { redirectToHome } from '../../../shared/utils';
import { createProvider, getPostUrl } from '../auth-utils';
import { useAnalyticEventCallback } from '../../analytic-event/analytic-event-hooks';
import { openDialogAlert } from '../../alert/alert-actions';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  height: '38px !important',
  paddingRight: '30px!important',
  border: '1px solid #000000!important',
  backgroundColor: 'white !important',
});

const StyledImage = styled('img')({
  marginRight: 10,
});

export const GoogleButton: React.FC = () => {
  const { analyticsCallback } = useAnalyticEventCallback();
  const [, setCookie] = useCookies([USER_COOKIE, 'analytics_uuid']);

  const [googleSignupCallback] = useGoogleSignupMutation({
    onCompleted: ({ googleSignup }) => {
      setCookie(USER_COOKIE, googleSignup?.token, COOKIES_OPTIONS);
      createProvider('Google');
      redirectToHome('/');
    },
    onError: () =>
      openDialogAlert('Tenemos problemas para identificar el usuario google'),
  });

  const googleLogin = useGoogleLogin({
    onSuccess: ({ access_token }) => {
      googleSignupCallback({
        variables: {
          token: access_token,
          url: getPostUrl(),
          site: Site.CriterioHidalgo,
        },
      });
    },

    onError: () => openDialogAlert('Tenemos problemas con google'),
  });

  return (
    <>
      <StyledButton
        type="button"
        className=""
        variant="outlined"
        fullWidth
        onClick={() => {
          googleLogin();
          analyticsCallback('gmail_clicked');
        }}
        id="google-event-analytics"
      >
        <StyledImage src="/google-logo.png" width={20} height={20} alt="logo" />
        Continuar con Google
      </StyledButton>
    </>
  );
};
