import React from 'react';
import { Box, Theme } from '@mui/material';

import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import { createStyles, makeStyles } from '@mui/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    itemSocialBar: {
      color: '#ffffff!important',
      cursor: 'pointer',
      textDecoration: 'none',
      height: '28px',
      width: '28px',
      borderRadius: '100%',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
      '&:hover': {
        backgroundColor: 'black !important',
      },
    },
    logosColor: {
      color: 'white',
    },
  }),
);
export const SocialBar: React.FC = () => {
  const classes = useStyles();
  return (
    <Box
      justifyContent={'center'}
      alignItems={'center'}
      display={'flex'}
      component={'div'}
      flexWrap={'wrap'}
    >
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.threads.net/@criteriohidalgo"
        fontWeight="bold"
      >
        <img
          src="/threads.svg"
          alt="Threads Link"
          width={'18px'}
          height={'18px'}
        />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://whatsapp.com/channel/0029VaCBPbeKwqSLMJkUbx2U"
      >
        <WhatsAppIcon
          className={classes.logosColor}
          color="inherit"
          fontSize="medium"
        />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.facebook.com/criteriohidalgo/"
        fontWeight="bold"
      >
        <FacebookIcon className={classes.logosColor} fontSize="medium" />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://twitter.com/CriterioHidalgo"
      >
        <XIcon
          className={classes.logosColor}
          color="inherit"
          fontSize="medium"
        />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.instagram.com/criteriohidalgo/"
        fontWeight="bold"
      >
        <InstagramIcon className={classes.logosColor} fontSize="medium" />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.tiktok.com/@noticiascriterio?lang=es"
        fontWeight="bold"
      >
        <img
          src="/tiktok.svg"
          alt="Tik-Tok Link"
          width={'18px'}
          height={'18px'}
        />
      </Box>
      <Box
        className={classes.itemSocialBar}
        target="_blank"
        component="a"
        href="https://www.youtube.com/channel/UCj5wH2Kqkm5Nyk5pH94xaCA"
        fontWeight="bold"
      >
        <YouTubeIcon className={classes.logosColor} fontSize="medium" />
      </Box>
    </Box>
  );
};
