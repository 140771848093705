import { AuthStep } from './auth-types';
import { universalCookies } from '../../shared/cookies';
import { COOKIES_OPTIONS } from '../../shared/constans';

const query = new URLSearchParams(window.location.search);

export const getCurrentUrl = (): string => {
  return query.has('current_url')
    ? query.get('current_url') || ''
    : window.location.href;
};

export const getFormType = (): string | null => {
  return query.has('form') ? query.get('form') : '';
};

export const getResetPasswordToken = (): string => {
  return query.has('password_reset_token')
    ? (query.get('password_reset_token') as string)
    : '';
};

export const getError = (): string => {
  return query.has('error') ? (query.get('error') as string) : '';
};

export const getIsInStandaloneMode = (): string | boolean => {
  return query.has('isInStandaloneMode')
    ? (query.get('isInStandaloneMode') as string)
    : false;
};

export const getIsLogin = (): boolean => {
  return query.has('action') && query.get('action') === 'login';
};

export const getStep = (): AuthStep => {
  return query.has('action')
    ? (query.get('action') as AuthStep)
    : AuthStep.authLogin;
};

export const getEmail = (): string => {
  return query.has('email') ? (query.get('email') as string) : '';
};

export const getFacebookState = (): string => {
  return query.has('state') ? (query.get('state') as string) : '';
};

export const getIsIFrame = (): boolean => {
  return query.has('frame') && query.get('frame') === '1';
};

export const createProvider = (provider: string): void => {
  universalCookies.set('_provider', provider, COOKIES_OPTIONS);
};

export const getRedirectUrl = (): string => {
  return query.has('redirect_url')
    ? decodeURIComponent(query.get('redirect_url') as string)
    : '';
};

export const getPostUrl = (): string => {
  return query.has('post_url')
    ? decodeURIComponent(query.get('post_url') as string)
    : '';
};

export const getTestMode = (): string => {
  return query.has('test_mode')
    ? decodeURIComponent(query.get('test_mode') as string)
    : '';
};
