import React from 'react';
import {
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  Theme,
  Box,
  Hidden,
  Drawer,
  ListItemIcon,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { SETTING_ROUTES } from './layout-utils';
import { PRIMARY_COLOR } from '../../../css/theme';
import { openMobileMenuEvent } from './user-events';
import { useStore } from '@cobuildlab/react-simple-state';
import { closeMobileMenu } from './user-actions';
import LaunchIcon from '@mui/icons-material/Launch';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    textRed: {
      color: PRIMARY_COLOR,
    },
    textNormal: {
      color: 'black',
      cursor: 'pointer',
    },
    listItem: {
      '& > span': {
        fontWeight: 'bold',
      },
    },
    helpCenter: {
      color: 'black',
      '&:hover': {
        color: '#0080ff',
      },
    },
  }),
);

export const Menu: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { open } = useStore(openMobileMenuEvent);

  return (
    <>
      <Hidden mdDown={false} mdUp={true}>
        <Drawer anchor="left" open={open} onClose={() => closeMobileMenu()}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            justifyContent={'space-between'}
            sx={{ width: 250 }}
            onClick={() => closeMobileMenu()}
          >
            <Box>
              <ListItem divider>
                <Typography
                  fontFamily="AgencyBold"
                  letterSpacing={1}
                  fontWeight="bold"
                >
                  MI CUENTA
                </Typography>
              </ListItem>
              {SETTING_ROUTES.map((route) => {
                return (
                  <ListItem
                    divider
                    className={
                      route.path === location.pathname
                        ? classes.textRed
                        : classes.textNormal
                    }
                    onClick={() => {
                      navigate(route.path);
                    }}
                  >
                    <ListItemText primary={route.name} />
                    <ArrowForwardIosIcon fontSize="small" />
                  </ListItem>
                );
              })}

              <List component="nav" aria-label="mailbox folders">
                <ListItem divider>
                  <Typography
                    fontWeight="bold"
                    fontFamily="AgencyBold"
                    letterSpacing={1}
                  >
                    AYUDA
                  </Typography>
                </ListItem>
              </List>

              <List component="nav" aria-label="mailbox folders">
                <ListItem
                  divider
                  component="a"
                  href="mailto:suscripciones@animalpolitico.com"
                  target="_blank"
                  style={{ color: 'black' }}
                >
                  <ListItemText primary="Contáctenos" />
                  <MailOutlineIcon fontSize="small" />
                </ListItem>

                <a
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    display: 'none',
                  }}
                  target="_blank"
                  href="#"
                >
                  <ListItem divider>
                    <ListItemText
                      style={{ fontSize: '16px' }}
                      primary="Escríbenos a Whatsapp"
                    />
                    <WhatsAppIcon fontSize="small" />
                  </ListItem>
                </a>
                <a
                  style={{
                    textDecoration: 'none',
                    color: 'black',
                    display: 'none',
                  }}
                  target="_blank"
                  href="#"
                >
                  <ListItem divider>
                    <ListItemText
                      style={{ fontSize: '16px' }}
                      primary="Centro de ayuda"
                    />
                    <LaunchIcon fontSize="small" />
                  </ListItem>
                </a>
              </List>
            </Box>
            <Box>
              <div>
                <Divider />
                <ListItem
                  className={classes.textNormal}
                  onClick={() => navigate('/logout')}
                >
                  <Box marginRight={1} display={'flex'} alignItems={'center'}>
                    <img
                      src="/icon-power-off.svg"
                      alt="icono salis sesión"
                      width={'18px'}
                      height={'18px'}
                    />
                  </Box>
                  <ListItemText
                    primary={
                      <Typography fontWeight={700} letterSpacing={0.5}>
                        Cerrar sesión
                      </Typography>
                    }
                  />
                </ListItem>
              </div>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown={true} mdUp={false}>
        <Box>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <Typography
                fontFamily="AgencyBold"
                letterSpacing={1}
                fontWeight="bold"
              >
                MI CUENTA
              </Typography>
            </ListItem>
            {SETTING_ROUTES.map((route) => {
              return (
                <ListItem
                  divider
                  className={
                    route.path === location.pathname
                      ? classes.textRed
                      : classes.textNormal
                  }
                  onClick={() => {
                    navigate(route.path);
                  }}
                >
                  <ListItemText primary={route.name} />
                  <ArrowForwardIosIcon fontSize="small" />
                </ListItem>
              );
            })}
          </List>
          <List component="nav" aria-label="mailbox folders">
            <ListItem divider>
              <Typography fontWeight="bold">AYUDA</Typography>
            </ListItem>
          </List>

          <List component="nav" aria-label="mailbox folders">
            <ListItem
              divider
              component="a"
              href="mailto:suscripciones@animalpolitico.com"
              target="_blank"
              style={{ color: 'black' }}
            >
              <ListItemText primary="Contáctenos" />
              <MailOutlineIcon fontSize="small" />
            </ListItem>

            <a
              style={{
                textDecoration: 'none',
                color: 'black',
                display: 'none',
              }}
              target="_blank"
              href="#"
            >
              <ListItem divider>
                <ListItemText
                  style={{ fontSize: '16px' }}
                  primary="Escríbenos a Whatsapp"
                />
                <WhatsAppIcon fontSize="small" />
              </ListItem>
            </a>
            <a
              style={{
                textDecoration: 'none',
                color: 'black',
                display: 'none',
              }}
              target="_blank"
              href="#"
            >
              <ListItem divider>
                <ListItemText
                  style={{ fontSize: '16px' }}
                  primary="Centro de ayuda"
                />
                <LaunchIcon fontSize="small" />
              </ListItem>
            </a>

            <div style={{ marginTop: '200px' }}>
              <Divider />
              <ListItem
                className={classes.textNormal}
                onClick={() => navigate('/logout')}
              >
                <ListItemIcon style={{ minWidth: '26px' }}>
                  <img
                    src="/icon-power-off.svg"
                    alt="icono salis sesión"
                    width={'18px'}
                    height={'18px'}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography fontWeight={700} letterSpacing={0.5}>
                      Cerrar sesión
                    </Typography>
                  }
                />
              </ListItem>
            </div>
          </List>
        </Box>
      </Hidden>
    </>
  );
};
