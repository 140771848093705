import { MailchimpSubscription } from '../../shared/types/generated';

export const getMailchimpAudienceStatus = (
  mailchimpSubscription: MailchimpSubscription[],
  audience: string,
): MailchimpSubscription | undefined => {
  return mailchimpSubscription.find(
    (subs) => subs?.status === 'subscribed' && subs.audience === audience,
  );
};
