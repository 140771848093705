import React from 'react';
import { Box, Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useAuth } from '../../auth/auth-hook';
import AppleLogin from 'react-apple-login';
import { openDialogAlert } from '../../alert/alert-actions';
import { APP_ENV } from '../../../shared/constans';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import AppleIcon from '@mui/icons-material/Apple';
import {
  SocialProviders,
  useConnectUserAppleMutation,
} from '../../../shared/types/generated';
import { providerConnectStoreAction } from '../setting-actions';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    connect: {
      background: `${PRIMARY_COLOR}!important`,
      color: 'black!important',
      fontWeight: ' bold !important',
      width: '130px',
      height: '38px !important',
    },
    disconnect: {
      background: '#efefef!important',
      color: `${PRIMARY_COLOR}!important`,
      border: '1px solid #FA0000',
      width: '130px',
      height: '38px !important',
    },
    textSocial: {
      margin: '0px 0px 0px 15px!important',
    },
    disable: {
      background: '#efefef!important',
      opacity: '65% !important',
    },
  }),
);

export const AppleConnectButton: React.FC = () => {
  const classes = useStyles();
  const { user, refetch } = useAuth();

  const isConnected = user?.social_providers?.find(
    (socialProvider) => socialProvider?.provider === SocialProviders.Apple,
  );

  const [callbackAction, { loading }] = useConnectUserAppleMutation({
    onCompleted: () => {
      refetch();
      return openDialogAlert(
        'Se ha conectado tu cuenta de apple correctamente',
      );
    },
    onError: (error) => {
      return openDialogAlert(error.message);
    },
  });

  const handleDisconnect = (): void => {
    if (isConnected?.primary)
      return openDialogAlert('No se puede desconectar su cuenta principal');

    providerConnectStoreAction(true, SocialProviders.Apple);
  };

  if (APP_ENV === 'local') return null;

  return (
    <Grid container>
      <Grid item xs={6} sm={8} alignItems="center">
        <Grid container height={'100%'}>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <AppleIcon />
          </Grid>
          <Grid item xs={12} sm={6} display="flex" alignItems="center">
            <Box display={'flex'} alignItems={'center'}>
              <CheckCircleOutlineIcon
                fontSize="small"
                style={{ color: 'green' }}
              />
              <Box style={{ color: 'green' }} className={classes.textSocial}>
                Conectada
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sm={4} textAlign="center">
        <AppleLogin
          clientId="mx.vang.suscriptores.client"
          redirectURI="https://miperfil.vanguardia.com.mx/apple/callback"
          callback={(data: {
            authorization: { code?: string; id_token?: string };
          }) => {
            if (data?.authorization && data?.authorization?.id_token) {
              callbackAction({
                variables: {
                  data: {
                    token: data?.authorization?.id_token,
                  },
                },
              });
            }
          }} // Catch the response
          scope="name email"
          usePopup
          responseMode="query"
          render={(
            renderProps, //Custom Apple Sign in Button
          ) => (
            <ButtonDefault
              isLoading={loading}
              variant="outlined"
              fullWidth
              className={!isConnected ? classes.connect : classes.disconnect}
              onClick={() =>
                !isConnected ? renderProps.onClick() : handleDisconnect()
              }
            >
              {!isConnected ? 'Conectar' : 'desconectar'}
            </ButtonDefault>
          )}
        />
      </Grid>
    </Grid>
  );
};
