import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { SocialBar } from './SocialBar';
import { PageAssociated } from '../pagesAssociated';
import { PRIMARY_COLOR } from '../../../css/theme';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      padding: '16px 74px !important',
      paddingTop: '15px !important',
      background: '#333333',
      alignItems: 'center',
      borderTop: `3px solid ${PRIMARY_COLOR}`,
      [theme.breakpoints.down('md')]: {
        padding: '10px 10px !important',
      },
    },
    rootLogos: {
      padding: '16px 74px !important',
      paddingTop: '15px !important',
      background: '#F3F4F6',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        padding: '10px 10px !important',
      },
    },
    rootItems: {
      padding: '0 70px !important',
      [theme.breakpoints.down('md')]: {
        padding: '0 0px !important',
      },
    },
    item: {
      fontSize: '16px !important',
      fontFamily: 'Inter',
      fontWeight: 'bold !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
        paddingBottom: '10px',
      },
    },
    boxWithBorder: {
      color: '#FFFFFF',
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      textDecoration: 'none',
      textAlign: 'center',
      borderRight: '1px solid white',
      [theme.breakpoints.down('xl')]: {
        borderRight: 'none',
        padding: '10px 0px 0px 0px',
      },
    },
    boxWithoutBorder: {
      color: '#FFFFFF',
      cursor: 'pointer',
      textDecoration: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      textAlign: 'center',
      [theme.breakpoints.down('xl')]: {
        borderRight: 'none',
        padding: '10px 0px 0px 0px',
      },
    },
    generalBox: {
      justifyContent: 'space-between',
      alignItems: 'center',
      display: 'flex',
      width: '100%',
      background: '#000000',
    },
    flexCenter: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    flexCenterItemsMenu: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
    },
    socialBarClasses: {
      [theme.breakpoints.down('xl')]: {
        padding: '15px 0px 0px 0px !important',
      },
    },
  }),
);

const ItemsFooter = [
  {
    text: 'Aviso de privacidad',
    url: 'https://criteriohidalgo.com/aviso-de-privacidad',
  },
  {
    text: 'Código de ética',
    url: 'https://www.animalpolitico.com/codigo-de-etica',
  },
  {
    text: 'Directorio General',
    url: 'https://www.animalpolitico.com/directorio',
  },
  {
    text: 'Preguntas frecuentes',
    url: 'https://www.animalpolitico.com/preguntas-frecuentes',
  },
  {
    text: '¿Quiénes somos?',
    url: 'https://www.animalpolitico.com/quienes-somos',
  },
  {
    text: 'Anúnciate con nosotros',
    url: 'https://www.animalpolitico.com/anunciate',
  },
];
type Props = {
  withLogos?: boolean;
};
export const FooterAuth: React.FC<Props> = ({ withLogos = false }) => {
  const classes = useStyles();
  return (
    <Box>
      {withLogos ? (
        <Grid
          container
          component={'div'}
          justifyContent={'center'}
          className={classes.rootLogos}
        >
          <Grid item xs={12}>
            <Box
              marginBottom={2}
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <img
                src="./logoCriterioGrande.png"
                alt="Criterio Grande"
                width={100}
                height={62}
              />
            </Box>
          </Grid>
          <Grid xs={12} md={6} lg={5} item>
            <PageAssociated />
          </Grid>
        </Grid>
      ) : null}
      <Grid container component={'div'} className={classes.root}>
        <Grid item xs={12} xl={10} className={classes.rootItems}>
          <Grid container xs={12}>
            {ItemsFooter.map((item, i) => {
              return (
                <Grid
                  item
                  xs={6}
                  sm={4}
                  xl={2}
                  className={classes.flexCenterItemsMenu}
                >
                  <Box
                    className={
                      i < ItemsFooter.length - 1
                        ? classes.boxWithBorder
                        : classes.boxWithoutBorder
                    }
                    target="_blank"
                    component="a"
                    href={item.url}
                    fontWeight="bold"
                    width={'100%'}
                    textAlign={'center'}
                  >
                    <Typography component={'p'} className={classes.item}>
                      {item.text}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} xl={2} className={classes.socialBarClasses}>
          <Box className={classes.flexCenter}>
            <SocialBar />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
