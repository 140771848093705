import { createTheme } from '@mui/material/styles';

export const PRIMARY_COLOR = '#76B82A';
export const SECONDARY_COLOR = '#76B82A99';
export const LOADING_COLOR = '#E3FFC2';

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
  //"PT Sans",helvetica,arial,sans-serif
  typography: {
    fontFamily: ['Bentonsans'].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 38,
          '&.Mui-disabled': {
            background: SECONDARY_COLOR,
            color: '#fff',
          },
          '&': {
            border: '1px solid #CCCCCC',
            color: '#999999',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #9CA5AC',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#9CA5AC',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#9CA5AC',
        },
      },
    },
  },
});
