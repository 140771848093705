import React from 'react';
import { styled } from '@mui/styles';
import { Button } from '@mui/material';
// @ts-ignore
import { ReactFacebookLoginInfo } from 'react-facebook-login';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import {
  COOKIES_OPTIONS,
  FACEBOOK_APP_ID,
  FACEBOOK_CALLBACK,
  USER_COOKIE,
} from '../../../shared/constans';
import MobileDetect from 'mobile-detect';
import { createProvider, getFacebookState, getPostUrl } from '../auth-utils';
import { useCookies } from 'react-cookie';
import {
  Site,
  useFacebookSignupMutation,
} from '../../../shared/types/generated';
import { redirectToHome } from '../../../shared/utils';
import { useAnalyticEventCallback } from '../../analytic-event/analytic-event-hooks';
import { openDialogAlert } from '../../alert/alert-actions';

const StyledButton = styled(Button)({
  position: 'relative',
  justifyContent: 'center!important',
  borderRadius: '5px!important',
  backgroundColor: 'white !important',
  height: '38px !important',
  border: '1px solid #000000!important',
});

const StyledImage = styled('img')({
  marginRight: 10,
});

export const FacebookButton: React.FC = () => {
  const agent = new MobileDetect(window.navigator.userAgent);
  const [, setCookie] = useCookies(['analytics_uuid', USER_COOKIE]);
  const { analyticsCallback } = useAnalyticEventCallback();

  const [facebookSignupCallback] = useFacebookSignupMutation({
    onCompleted: ({ facebookSignup }) => {
      setCookie(USER_COOKIE, facebookSignup?.token, COOKIES_OPTIONS);
      createProvider('Facebook');
      redirectToHome('/');
    },

    onError: () =>
      openDialogAlert('Tenemos problemas para identificar el usuario facebook'),
  });

  const responseFacebook = (userInfo: ReactFacebookLoginInfo): void => {
    facebookSignupCallback({
      variables: {
        token: userInfo.accessToken,
        url: getPostUrl(),
        site: Site.CriterioHidalgo,
      },
    });
  };

  if (agent.mobile() && agent.phone()) {
    return (
      <FacebookLogin
        appId={FACEBOOK_APP_ID as string}
        callback={responseFacebook}
        fields="name,email,picture"
        redirectUri={`${FACEBOOK_CALLBACK}/callback/facebook`}
        responseType="token"
        state={getFacebookState()}
        isMobile
        render={(renderProps) => {
          return (
            <StyledButton
              type="button"
              className=""
              variant="outlined"
              fullWidth
              onClick={() => {
                analyticsCallback('facebook_mobile_clicked');
                renderProps.onClick();
              }}
            >
              <StyledImage
                src="/facebook-icon.png"
                width={18}
                height={18}
                alt="logo"
              />
              Continuar con Facebook
            </StyledButton>
          );
        }}
      />
    );
  }

  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID as string}
      callback={responseFacebook}
      fields="name,email,picture"
      isMobile={false}
      autoLoad={false}
      scope="public_profile,email"
      render={(renderProps) => {
        return (
          <StyledButton
            type="button"
            className=""
            variant="outlined"
            fullWidth
            id="facebook-event-analytics"
            onClick={() => {
              renderProps.onClick();
              analyticsCallback('facebook_clicked');
            }}
          >
            <StyledImage
              src="/facebook-icon.png"
              width={18}
              height={18}
              alt="logo"
            />
            Continuar con Facebook
          </StyledButton>
        );
      }}
    />
  );
};
